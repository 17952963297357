import { environment as prodEnvironment } from 'src/environments/environment.prod';
import { environment as devEnvironment } from 'src/environments/environment';

export const JSON_SERVER_URL="http://localhost:3000/authResponse";
export const NG_SERVE_URL = "http://localhost:4200/";
export const DUMMY_URL = "../../assets/dummy-response/";
export const GET_CYCLE_DUMMY = "cycle.json";
export const AUTH_MEMBER_DUMMY = "authResponse.json";

export const DUMMY_GET_CYCLE = DUMMY_URL + GET_CYCLE_DUMMY;
export const DUMMY_AUTH_MEMBER = DUMMY_URL + AUTH_MEMBER_DUMMY;

export const API_URL_DEV = "https://dev-nominations-api.sagaftra.org/";
// export const API_URL_DEV = "http://dev-vortal.sagaftra.org:8481/";
export const API_URL_PROD = "https://nominations-api.sagaftra.org/";
export const GUI_URL_DEV = "https://dev-nominations.sagaftra.org/";
export const GUI_URL_PROD = "https://nominations.sagaftra.org/";
export const REFERRED_FROM_DEV = "https://test-www.sagaftra.org/";
export const REFERRED_FROM_PROD = "https://www.sagaftra.org/";
export const MANAGE_PETITIONS_DEV = "https://dev-nominations.sagaftra.org/home/manage-petitions";
export const MANAGE_PETITIONS_PROD = "https://nominations.sagaftra.org/home/manage-petitions";
export const BASE_PATH = "api/v1/";
// export const BASE_PATH = "nominations/v1/";
export const CREATE_ACCOUNT_DEV = "https://dev-www.sagaftra.org/create-account";
export const CREATE_ACCOUNT_PROD = "https://www.sagaftra.org/create-account";

export const GET_CYCLE_DEV = API_URL_DEV + BASE_PATH + "information/cycles";
// export const GET_CYCLE_DEV = API_URL_DEV + BASE_PATH + "cycle/";
export const GET_CYCLE_PROD = API_URL_PROD + BASE_PATH + "information/cycles";
export const AUTH_MEMBER_DEV = API_URL_DEV + BASE_PATH + "authenticate/member";
export const AUTH_MEMBER_PROD = API_URL_PROD + BASE_PATH + "authenticate/member";
export const AUTH_MEMBER_SSO_DEV = API_URL_DEV + BASE_PATH + "authenticate/member/sso";
export const AUTH_MEMBER_SSO_PROD = API_URL_PROD + BASE_PATH + "authenticate/member/sso";
export const GET_BOARD_DEV = API_URL_DEV + BASE_PATH + "users/board/";
export const GET_BOARD_PROD = API_URL_PROD + BASE_PATH + "users/board/";
// export const GET_USER_PETITIONS_DEV = API_URL_DEV + BASE_PATH + "petitions/user/all/";
export const GET_USER_PETITIONS_DEV = API_URL_DEV + BASE_PATH + "petitions/member/current-petitions";
export const GET_USER_PETITIONS_PROD = API_URL_PROD + BASE_PATH + "petitions/member/current-petitions";
// export const GET_USER_CONTACT_INFO_DEV = API_URL_DEV + BASE_PATH + "users/contact-information/";
export const GET_USER_CONTACT_INFO_DEV = API_URL_DEV + BASE_PATH + "petitions/member/contact-information";
export const GET_USER_CONTACT_INFO_PROD = API_URL_PROD + BASE_PATH + "petitions/member/contact-information";
export const UPDATE_USER_CONTACT_INFO_DEV = API_URL_DEV + BASE_PATH + "petitions/member/contact-information/update";
export const UPDATE_USER_CONTACT_INFO_PROD = API_URL_PROD + BASE_PATH + "petitions/member/contact-information/update";
export const GET_USER_BOARD_OPTIONS_DEV = API_URL_DEV + BASE_PATH + "petitions/member/all";
export const GET_USER_BOARD_OPTIONS_PROD = API_URL_PROD + BASE_PATH + "petitions/member/all";
// export const GET_USER_PETITIONS_SIGNATURES_DEV = API_URL_DEV + BASE_PATH + "petitions/petitioners/signatures/all/";
export const GET_USER_PETITIONS_SIGNATURES_NAMES_DEV = API_URL_DEV + BASE_PATH + "petitions/get-signatures";
export const GET_USER_PETITIONS_SIGNATURES_NAMES_PROD = API_URL_PROD + BASE_PATH + "petitions/get-signatures";
export const GET_USER_POSITIONS_DEV = API_URL_DEV + BASE_PATH + "petitions/positions";
export const GET_USER_POSITIONS_PROD = API_URL_PROD + BASE_PATH + "petitions/positions";
// export const GET_USER_PETITION_PDF_DEV = API_URL_DEV + BASE_PATH + "pdf/";
export const GET_USER_PETITION_PDF_DEV = API_URL_DEV + BASE_PATH + "petitions/pdf";
export const GET_USER_PETITION_PDF_PROD = API_URL_PROD + BASE_PATH + "petitions/pdf";
export const GET_USER_STATEMENT_DEV = API_URL_DEV + BASE_PATH + "petitions/get-statement";
export const GET_USER_STATEMENT_PROD = API_URL_PROD + BASE_PATH + "petitions/get-statement";
export const CREATE_USER_STATEMENT_DEV = API_URL_DEV + BASE_PATH + "petitions/create-statement";
export const CREATE_USER_STATEMENT_PROD = API_URL_PROD + BASE_PATH + "petitions/create-statement";
export const CREATE_PETITION_DEV = API_URL_DEV + BASE_PATH + "petitions/create";
export const CREATE_PETITION_PROD = API_URL_PROD + BASE_PATH + "petitions/create";
export const WITHDRAW_PETITION_DEV = API_URL_DEV + BASE_PATH + "petitions/withdraw";
export const WITHDRAW_PETITION_PROD = API_URL_PROD + BASE_PATH + "petitions/withdraw";
export const FIND_MEMBER_ADMIN_CODE_DEV = API_URL_DEV + BASE_PATH + "administrate/find";
export const FIND_MEMBER_ADMIN_CODE_PROD = API_URL_PROD + BASE_PATH + "administrate/find";
export const ADD_MEMBER_ADMIN_CODE_DEV = API_URL_DEV + BASE_PATH + "administrate/add";
export const ADD_MEMBER_ADMIN_CODE_PROD = API_URL_PROD + BASE_PATH + "administrate/add";
export const GET_ALL_BY_ADMIN_CODE_DEV = API_URL_DEV + BASE_PATH + "administrate/all";
export const GET_ALL_BY_ADMIN_CODE_PROD = API_URL_PROD + BASE_PATH + "administrate/all";
export const GET_MEMBER_PETITIONS_ADMIN_CODE_DEV = API_URL_DEV + BASE_PATH + "administrate/member";
export const GET_MEMBER_PETITIONS_ADMIN_CODE_PROD = API_URL_PROD + BASE_PATH + "administrate/member";
export const GET_MEMBER_PETITIONS_CAMPAIGN_ADMIN_DEV = API_URL_DEV + BASE_PATH + "administrate/view-all";
export const GET_MEMBER_PETITIONS_CAMPAIGN_ADMIN_PROD = API_URL_PROD + BASE_PATH + "administrate/view-all";
export const GET_PETITIONS_BY_SIGNING_ROUTE_DEV = API_URL_DEV + BASE_PATH + "petitions/find";
export const GET_PETITIONS_BY_SIGNING_ROUTE_PROD = API_URL_PROD + BASE_PATH + "petitions/find";
export const SIGN_MEMBER_PETITIONS_DEV = API_URL_DEV + BASE_PATH + "petitions/sign-petitions";
export const SIGN_MEMBER_PETITIONS_PROD = API_URL_PROD + BASE_PATH + "petitions/sign-petitions";
export const UPLOAD_PHOTO_DEV = API_URL_DEV + BASE_PATH + "petitions/photo";
export const UPLOAD_PHOTO_PROD = API_URL_PROD + BASE_PATH + "petitions/photo";
export const EMAIL_HELP_DEV = API_URL_DEV + BASE_PATH + 'loginhelp';
export const EMAIL_HELP_PROD = API_URL_PROD + BASE_PATH + 'loginhelp';

// const DEV_ASSETS_PATH = "../assets/";
const DEV_ASSETS_PATH = devEnvironment.assetsPath;
const PROD_ASSETS_PATH = prodEnvironment.assetsPath;

export const DEV_LOGO = DEV_ASSETS_PATH + "iXbt5f5qSU2W7REoi24j_SAlogo_horz_k_200.png";
export const DEV_PROFILE_LOGO = DEV_ASSETS_PATH + "profile-icon.png";
export const DEV_BANNER_IMG = DEV_ASSETS_PATH + "H8UYbhMrTFaLGKey0BMn_123c_brush4.png";
export const DEV_UPLOAD_IMG = DEV_ASSETS_PATH + "upload.svg";

export const PROD_LOGO = PROD_ASSETS_PATH + "iXbt5f5qSU2W7REoi24j_SAlogo_horz_k_200.png";
export const PROD_BANNER_IMG = PROD_ASSETS_PATH + "H8UYbhMrTFaLGKey0BMn_123c_brush4.png";

export const SAG_CONTACT_US = "https://www.sagaftra.org/contact-us";

// Staff constants
export const AUTH_STAFF_DEV = API_URL_DEV + BASE_PATH + "authenticate/staff";
export const AUTH_STAFF_PROD = API_URL_PROD + BASE_PATH + "authenticate/staff";
export const STAFF_TRACKING_DEV = API_URL_DEV + BASE_PATH + "staff/tracking";
export const STAFF_TRACKING_PROD = API_URL_PROD + BASE_PATH + "staff/tracking";
export const ORACLE_DEV = "http://oracle.sag.org:8000";
export const STAFF_GET_PETITION_SIGNATURES_DEV = API_URL_DEV + BASE_PATH + "staff/get-signatures";
export const STAFF_GET_PETITION_SIGNATURES_PROD = API_URL_PROD + BASE_PATH + "staff/get-signatures";
export const STAFF_PETITION_DETAILS_UPDATE_DEV = API_URL_DEV + BASE_PATH + "staff/petition/update";
export const STAFF_PETITION_DETAILS_UPDATE_PROD = API_URL_PROD + BASE_PATH + "staff/petition/update";
export const STAFF_ACTIVE_BOARDS_DEV = API_URL_DEV + BASE_PATH + "staff/active-boards";
export const STAFF_ACTIVE_BOARDS_PROD = API_URL_PROD + BASE_PATH + "staff/active-boards";
export const STAFF_ACTIVE_BOARD_POSITIONS_DEV = API_URL_DEV + BASE_PATH + "staff/positions";
export const STAFF_ACTIVE_BOARD_POSITIONS_PROD = API_URL_PROD + BASE_PATH + "staff/positions";
export const STAFF_OFFLINE_PETITION_PDF_DEV = API_URL_DEV + BASE_PATH + "staff/pdf";
export const STAFF_OFFLINE_PETITION_PDF_PROD = API_URL_PROD + BASE_PATH + "staff/pdf";
export const STAFF_PETITION_STATEMENTS_DEV = API_URL_DEV + BASE_PATH + "staff/statements";
export const STAFF_PETITION_STATEMENTS_PROD = API_URL_PROD + BASE_PATH + "staff/statements";
export const STAFF_GET_BALLOT_NAMES_DEV = API_URL_DEV + BASE_PATH + "staff/get-ballots-names";
export const STAFF_GET_BALLOT_NAMES_PROD = API_URL_PROD + BASE_PATH + "staff/get-ballots-names";
export const STATE_LIST = [
    { label: 'Alabama', value: 'Alabama' },
    { label: 'Alaska', value: 'Alaska' },
    { label: 'Arizona', value: 'Arizona' },
    { label: 'Arkansas', value: 'Arkansas' },
    { label: 'California', value: 'California' },
    { label: 'Colorado', value: 'Colorado' },
    { label: 'Connecticut', value: 'Connecticut' },
    { label: 'Delaware', value: 'Delaware' },
    { label: 'Florida', value: 'Florida' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Hawaii', value: 'Hawaii' },
    { label: 'Idaho', value: 'Idaho' },
    { label: 'Illinois', value: 'Illinois' },
    { label: 'Indiana', value: 'Indiana' },
    { label: 'Iowa', value: 'Iowa' },
    { label: 'Kansas', value: 'Kansas' },
    { label: 'Kentucky', value: 'Kentucky' },
    { label: 'Louisiana', value: 'Louisiana' },
    { label: 'Maine', value: 'Maine' },
    { label: 'Maryland', value: 'Maryland' },
    { label: 'Massachusetts', value: 'Massachusetts' },
    { label: 'Michigan', value: 'Michigan' },
    { label: 'Minnesota', value: 'Minnesota' },
    { label: 'Mississippi', value: 'Mississippi' },
    { label: 'Missouri', value: 'Missouri' },
    { label: 'Montana', value: 'Montana' },
    { label: 'Nebraska', value: 'Nebraska' },
    { label: 'Nevada', value: 'Nevada' },
    { label: 'New Hampshire', value: 'New Hampshire' },
    { label: 'New Jersey', value: 'New Jersey' },
    { label: 'New Mexico', value: 'New Mexico' },
    { label: 'New York', value: 'New York' },
    { label: 'North Carolina', value: 'North Carolina' },
    { label: 'North Dakota', value: 'North Dakota' },
    { label: 'Ohio', value: 'Ohio' },
    { label: 'Oklahoma', value: 'Oklahoma' },
    { label: 'Oregon', value: 'Oregon' },
    { label: 'Pennsylvania', value: 'Pennsylvania' },
    { label: 'Rhode Island', value: 'Rhode Island' },
    { label: 'South Carolina', value: 'South Carolina' },
    { label: 'South Dakota', value: 'South Dakota' },
    { label: 'Tennessee', value: 'Tennessee' },
    { label: 'Texas', value: 'Texas' },
    { label: 'Utah', value: 'Utah' },
    { label: 'Vermont', value: 'Vermont' },
    { label: 'Virginia', value: 'Virginia' },
    { label: 'Washington', value: 'Washington' },
    { label: 'West Virginia', value: 'West Virginia' },
    { label: 'Wisconsin', value: 'Wisconsin' },
    { label: 'Wyoming', value: 'Wyoming' },
    { label: 'American Samoa', value: 'American Samoa' },
    { label: 'District of Columbia', value: 'District of Columbia' },
    { label: 'Federated States of Micronesia', value: 'Federated States of Micronesia' },
    { label: 'Guam', value: 'Guam' },
    { label: 'Marshall Islands', value: 'Marshall Islands' },
    { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
    { label: 'Palau', value: 'Palau' },
    { label: 'Puerto Rico', value: 'Puerto Rico' },
    { label: 'Virgin Islands', value: 'Virgin Islands' },
  ];
import { Component, OnInit } from '@angular/core';
import { PROD_BANNER_IMG, PROD_LOGO, DEV_LOGO, DEV_BANNER_IMG } from 'src/app/utils/constants/service-url-constants';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  year:number = 2023;
  logoPath = DEV_LOGO;
  logoBannerPath = PROD_BANNER_IMG;

  constructor() { }

  ngOnInit(): void { }

}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import { HelpComponent } from './help.component';
import { LogoModule } from '../logo/logo.module';

const helpRoutes: Routes = [
    {path: 'help', component: HelpComponent, data: {staticHelpPage: true} },
]

@NgModule({
    declarations: [ HelpComponent ],
    exports: [HelpComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        DropdownModule,
        InputTextModule,
        ButtonModule,
        LogoModule,
        RouterModule.forChild(helpRoutes),
    ],
})
export class HelpModule { }
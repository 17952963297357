import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MANAGE_PETITIONS_DEV, MANAGE_PETITIONS_PROD, REFERRED_FROM_DEV, REFERRED_FROM_PROD } from './utils/constants/service-url-constants';
import { LoginService } from './services/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  authenticate: any;
  accessKey: any;
  ssoHash: any;

  constructor( private _router: Router, private loginService: LoginService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.accessKey = document.cookie.split(';').filter((item) => item.trim().startsWith('access_key='))[0];
    if (this.accessKey) {
      if (this.accessKey.split("=")[1]) {
        return true;
      } else {
        document.cookie = "access_key=; SameSite=lax; max-age=-100000; Path=/; Secure=true";
        this._router.navigate(['login']);
        alert("clear your browser cookies");
      }
    } else if (location.href === MANAGE_PETITIONS_PROD && document.referrer.includes(REFERRED_FROM_PROD)) {
      this.ssoHash = document.cookie.split(";").filter((item) => item.trim().startsWith('nominate='))[0];
      if (!this.ssoHash) {
        this._router.navigate(['login']);
      }
      let authHash = {"hash": this.ssoHash.split('=')[1]};
      let ssoResp;
      this.loginService.authenticateMemberSSO(authHash).subscribe(resp => {
        ssoResp = resp;
        if (ssoResp && ssoResp['status'] == true) {
          document.cookie = `access_key=${ssoResp.payload.access_key}; SameSite=lax; Path=/; Secure=true`;
          document.cookie = `username=${ssoResp.payload.username}; SameSite=lax; Path=/; Secure=true`;
          this._router.navigate(['home/manage-petitions']);
        }
      }, err => {
        console.log(err);
      });
    } else {
      if (location.href.includes("home/manage-petitions")) {
        document.cookie = `managePetitions=true; SameSite=lax; Path=/; Secure=true`;
      }
      this._router.navigate(['login']);
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this._router.events
      .subscribe(e => {
        if (e instanceof NavigationEnd) {
          console.log(e, e.urlAfterRedirects);
          if (e.urlAfterRedirects === "/home/nominations/petitions-sign-up") {
            return true;
          }
        }
      });
      // alert("Not accessible Directly");
      return true;
    }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { LoginService } from '../services/login/login.service';
import { AuthMember } from '../services/login/login';
import { SAG_CONTACT_US, NG_SERVE_URL, REFERRED_FROM_DEV, REFERRED_FROM_PROD,
          MANAGE_PETITIONS_DEV, MANAGE_PETITIONS_PROD, CREATE_ACCOUNT_DEV,
          CREATE_ACCOUNT_PROD } from 'src/app/utils/constants/service-url-constants';
import { ApplicationTitleService } from '../utils/application-title.service';
import { LoaderService } from '../utils/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  cycleResp: any;
  isElectionsCycle: boolean = true;
  authResp: any;
  ssoResp: any;
  displayModal: boolean = false;
  displayBlockedModal: boolean = false;
  authAccessKey: any;
  staffAccessKey: any;
  ssoHash: any;
  electionYear: number;
  httpHeaders: any;
  signPetitions: any;
  signPetitionsCode: any;
  showLoader:boolean = false;
  createAccount: any = CREATE_ACCOUNT_PROD;
  username: any;
  isStaffLogin: boolean = false;
  isMemberLogin: boolean = false;
  signRoute: any = document.cookie.split(";").filter((item) => item.trim().startsWith('sign_route='))[0];
  formHelpModalHide: boolean = false;
  managePetitions = document.cookie.split(';').filter((item) => item.trim().startsWith('managePetitions='))[0];

  // move these to constants
  logoPath = "../assets/iXbt5f5qSU2W7REoi24j_SAlogo_horz_k_200.png";
  sagContactUs = SAG_CONTACT_US;

  constructor(private _router: Router,
              private loginService: LoginService,
              private applicationTitleService:ApplicationTitleService,
              private _activatedRoute: ActivatedRoute,
              private loaderService : LoaderService) {
    this.loaderService.setIsLoaded(false);
    this._activatedRoute.data.subscribe(data => {
      this.isStaffLogin = data.staff;
      console.log(this.isStaffLogin);
    });
    if (this.isStaffLogin) {
      this.applicationTitleService.setTitle('Nominations Staff Login');
    } else {
      this.isMemberLogin = true;
      this.applicationTitleService.setTitle('Nominations Login');
    }
    this.staffAccessKey = document.cookie.split(";").filter((item) => item.trim().startsWith('staff_key='))[0];
    console.log(this.staffAccessKey);
    if (this.staffAccessKey && (this.staffAccessKey.split('=')[1])) {
      this._router.navigate(['admin']);
      console.log("hello for admin");
    }
    this.getElectionCycle();
    this.authAccessKey = document.cookie.split(";").filter((item) => item.trim().startsWith('access_key='))[0];
    this.ssoHash = document.cookie.split(";").filter((item) => item.trim().startsWith('nominate='))[0];
    console.log("ssoHash",this.ssoHash);
    if (this.ssoHash && (this.ssoHash.split('=')[1]) && (document.referrer.includes(REFERRED_FROM_PROD))) {
      let authHash = {"hash": this.ssoHash.split('=')[1]};
      if (!(location.href === MANAGE_PETITIONS_PROD)) {
        if (this.authAccessKey) {
          this._router.navigate(['home']);
        }
        this.showLoader = true;
        this.loginService.authenticateMemberSSO(authHash).subscribe(resp => {
          console.log(resp);
          this.ssoResp = resp;
          if (this.ssoResp && (this.ssoResp['status'] == true)) {
            document.cookie = `access_key=${this.ssoResp.payload.access_key}; SameSite=strict; Path=/; Secure=true`;
            document.cookie = `username=${this.ssoResp.payload.username}; SameSite=strict; Path=/; Secure=true`;
            this._router.navigate(['home']);
            this.showLoader = false;
          }
        },err => {
          console.error(err);
          if(err['status'] == 403){
            this.showBlockedCredentialDailog();
            this.showLoader = false;
          }
        });
      }
    }
    this.loginForm = new FormGroup({
      'username': new FormControl('', Validators.compose(
        [Validators.required, Validators.minLength(4)]
      )),
      'password': new FormControl('', Validators.compose(
        [Validators.required, Validators.minLength(4)]
      )),
    });

    console.log(this.authAccessKey, "login page");
    if (this.authAccessKey) {
      if (this.authAccessKey.split("=")[1]) {
        this._router.navigate(['home']);
      }
    }
  }

  ngOnInit(): void { }

  login(form: FormGroup) {
    let authMember = new AuthMember();
    Object.assign(authMember, form.value);
    if (this.isStaffLogin) {
      this.loginService.authenticateStaff(authMember).subscribe(loginResp => {
        console.log(loginResp);
        if (loginResp && loginResp['status'] === true) {
          if (loginResp['payload']?.access_key) {
            document.cookie = `staff_key=${loginResp['payload'].access_key}; SameSite=strict; Path=/; Secure=true`;
            document.cookie = `staffname=${loginResp['payload'].username}; SameSite=strict; Path=/; Secure=true`;
            document.cookie = `isStaffAdmin=${loginResp['is_staff_admin']}; SameSite=strict; Path=/; Secure=true`;
          } else {
            document.cookie = `staff_key=${loginResp['payload']}; SameSite=strict; Path=/; Secure=true`;
          }
          console.log(document.cookie);
          this._router.navigate(['admin']);
        }
      }, err => {
        console.error(err);
        this.showIncorrectCredentialDailog();
      });
    } else {
      this.showLoader = true;
      this.loginService.authenticateMember(authMember).subscribe(loginResp => {
        this.authResp = loginResp;
        console.log(this.authResp);
        if (loginResp && loginResp['status'] === true) {
          document.cookie = `access_key=${this.authResp.payload.access_key}; SameSite=lax; Path=/; Secure=true`;
          document.cookie = `username=${this.authResp.payload.username}; SameSite=lax; Path=/; Secure=true`;
          if (this.signRoute) {
            this._router.navigate(['home/sign-petitions']);
          } else if (this.managePetitions) {
            this._router.navigate(['home/manage-petitions']);
          } else {
            this._router.navigate(['home']);
          }
          this.showLoader = false;
        } else {
          // this.authResp.failure;
          this.showIncorrectCredentialDailog();
          this.showLoader = false;
        }
      },
        err => {
          console.error(err);
          if(err['status'] == 403){
            this.showBlockedCredentialDailog();
            this.showLoader = false;
          }
          else{
            this.showIncorrectCredentialDailog();
            this.showLoader = false;

          }
        }
      );
    }
  }

  showIncorrectCredentialDailog() {
    this.displayModal = true;
  }  
  showBlockedCredentialDailog() {
    this.displayBlockedModal = true;
  }

  pageReload() {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        location.reload(true);
        console.log("from events");
      }
    });
  }

  getElectionCycle() {
    this.loginService.getCycle().subscribe(resp => {
      this.cycleResp = { ...resp };
      this.isElectionsCycle = this.cycleResp.payload?.active == '1';
      this.electionYear = this.cycleResp.payload?.election_year;
      console.log(this.cycleResp, "Election Cycle:", this.isElectionsCycle);
    },
      err => {
        this.isElectionsCycle = true;
        console.error(err);
      }
    )
  }

  formHelpEmitterHandler(value: boolean) {
    this.formHelpModalHide = value;
  }

}

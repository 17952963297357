<div class="p-grid sample-layout">
    <div class="p-col-12 p-col-nogutter">
        <app-logo></app-logo>
    </div>
    <div class="p-col-12 p-lg-10 p-md-12 p-sm-12 p-col-nogutter outlet">
        <div class="login-body">
            <div class="p-grid">
                <div class="p-col-12 login-heading" *ngIf="isMemberLogin">
                    Welcome to the Nominations Portal for the {{ electionYear }} SAG-AFTRA Elections.
                </div>
                <div class="p-col-12 login-heading" *ngIf="isStaffLogin">
                    Staff Login
                </div>
            </div>
            <p-card *ngIf="isElectionsCycle || isStaffLogin" styleClass="login-form">
                <div class="p-grid font-family" *ngIf="isMemberLogin">
                    <div class="p-col-12">
                        If you do not have an online account, you can create <a [href]="createAccount" class="a" target="_blank">one</a> here. For assistance creating an online account, please visit the SAGAFTRA.org <a class="a" [href]="sagContactUs">Contact Us</a>                        page.
                    </div>
                    <div class="p-col-12">
                        Please enter your SAGAFTRA.org Username and Password
                    </div>
                </div>
                <div class="p-grid font-family" *ngIf="isStaffLogin">
                    <div class="p-col-12">
                        Please enter your windows credentials.
                    </div>
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="font-family">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <label>
                                <span for="float-inputUserName">Username</span>
                            </label>
                        </div>
                        <div class="p-col-12">
                            <input class="p-col-12" id="float-inputUserName" type="text" formControlName="username" required /><br />
                            <div *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty && loginForm.controls.username.touched)">
                                <span *ngIf="loginForm.controls.username.errors.required">Username is
                                    required</span>
                                <span *ngIf="loginForm.controls.username.errors.minlength">Username must be minimum
                                    of 4
                                    characters</span>
                                <span *ngIf="loginForm.controls.username.errors.pattern">Username must contain atleast
                                    one alphabet,
                                    can contain only period, underscore and numbers</span>
                            </div>
                        </div>
                        <div class="p-col-12">
                            <label>
                                <span for="float-inputPassword">Password</span>
                            </label>
                        </div>
                        <div class="p-col-12">
                            <input class="p-col-12" id="float-inputPassword" type="password" formControlName="password" required /><br />
                            <div *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty && loginForm.controls.password.touched)">
                                <span *ngIf="loginForm.controls.password.errors.required">Password is
                                    required</span>
                                <span *ngIf="loginForm.controls.password.errors.minlength">Password must be minimum
                                    of 4
                                    characters</span>
                            </div>
                        </div>
                        <div class="p-col-12">
                            Forgot your Username or Password? <a *ngIf="!isStaffLogin; else staffLoginHelp" class="a" href="https://www.sagaftra.org/account-recovery">click here</a>.
                            <ng-template #staffLoginHelp>
                                Please contact the Helpdesk at <a class="a" href="mailto:helpdesk@sagaftra.org">helpdesk@sagaftra.org</a> or <a class="a" href="tel:323-549-6046">323-549-6046</a>
                            </ng-template>
                        </div>
                        <div class="p-col-12">
                            <button pButton type="submit" class="btn-custom fluid" [disabled]="loginForm.invalid" label="Sign
                                In"></button>
                        </div>
                    </div>
                </form>
                <p-blockUI [blocked]="showLoader">
                    <div class="ajax-progress ajax-progress-throbber">
                        <div class="throbber">
                        </div>
                    </div>
                </p-blockUI>
            </p-card>
            <!-- <p-blockUI [blocked]="showLoader">
                <div class="ajax-progress ajax-progress-throbber">
                    <div class="throbber">
                    </div>
                </div>
            </p-blockUI> -->

            <!-- <ng-container *ngIf="!isElectionsCycle"> -->
            <p-card *ngIf="!isElectionsCycle">
                <div class="p-col-12">
                    <h3>Nomination period has closed.</h3>
                </div>
            </p-card>
            <!-- </ng-container> -->
        </div>
    </div>
</div>

<div class="footer-bar">
    <div class="p-grid p-col-nogutter p-align-end">
        <div *ngIf="!isStaffLogin; else staffLoginFooter" class="p-col-9 p-lg-11 p-sm-10" style="height: 1.5em;background-color: #FED100;"></div>
        <a *ngIf="!isStaffLogin" class="p-col-3 p-lg-1 p-sm-2 footer-help" style="background-color: red;padding:1em;text-align:center;color:white;text-decoration:none;cursor:pointer" (click)="formHelpModalHide=true">
            NEED HELP?
        </a>
        <ng-template #staffLoginFooter>
            <div class="p-col-9 p-lg-12 p-sm-10" style="height: 1.5em;background-color: #FED100;"></div>
        </ng-template>
    </div>
</div>

<app-help *ngIf="formHelpModalHide" [formHelpModalHide]="formHelpModalHide" (formHelpModalShow)="formHelpEmitterHandler($event)"></app-help>

<!--incorrect credentials dialog-->
<p-dialog [(visible)]="displayModal" [modal]="true" styleClass="login-fail" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p-header class="login-fail-header">Login Failed</p-header>
    <div class="p-grid login-fail-body">
        <div class="p-col-12">Please try again.</div>
        <div class="margin-auto">
            <p *ngIf="!isStaffLogin; else staffLoginHelp">
                Forgot your Username or Password? <a class="a" href="https://www.sagaftra.org/account-recovery">click here</a>.
            </p>
            <ng-template #staffLoginHelp>
                Forgot your Username or Password?
                <p>Please contact the Helpdesk at <a class="a" href="mailto:helpdesk@sagaftra.org">helpdesk@sagaftra.org</a> or <a class="a" href="tel:323-549-6046">323-549-6046</a>.
                </p>
            </ng-template>
        </div>
    </div>
    <p-footer>
        <button type="button" class="btn-custom-cancel" pButton (click)="displayModal=false" label="Close"></button>
    </p-footer>
</p-dialog>

<!--incorrect credentials dialog-->
<p-dialog [(visible)]="displayBlockedModal" [modal]="true" styleClass="login-fail" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p-header class="login-fail-header">Login Denied</p-header>
    <div class="p-grid login-fail-body">
        <div class="p-col-12">Since you are serving as an election committee member, please contact  <a class="a" href="nationalelections@sagaftra.org">nationalelections@sagaftra.org</a> for assistance.</div>
    </div>
    <p-footer>
        <button type="button" class="btn-custom-cancel" pButton (click)="displayBlockedModal=false" label="Close"></button>
    </p-footer>
</p-dialog>

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class ApplicationTitleService{

    constructor(private _titleService:Title){

    }

    setTitle(title:string){
        this._titleService.setTitle(title);
    }
}
<div *ngIf="staticHelpPage" class="p-grid sample-layout">
    <div class="p-col-12 p-col-nogutter">
        <app-logo></app-logo>
    </div>
    <div class="p-col-12 p-lg-10 p-md-12 p-sm-12 p-col-nogutter outlet">
        <div class="p-grid mt-2">
            <div class="p-col-12 title-heading mb-2">
                Help Form
            </div>
            <div class="p-col-12">
                <form [formGroup]="helpForm" class="p-grid p-justify-start left-align help-form" (ngSubmit)="helpFormSubmit(helpForm)">
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                First Name*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input pInputText class="fluid" type="text" formControlName="firstName">
                        </div>
                        <div *ngIf="helpForm.controls.firstName.invalid && (helpForm.controls.firstName.dirty && helpForm.controls.firstName.touched)">
                            <span *ngIf="helpForm.controls.firstName.errors.required">
                                    Firstname is required
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Last Name*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input pInputText class="fluid" type="text" formControlName="lastName">
                        </div>
                        <div *ngIf="helpForm.controls.lastName.invalid && (helpForm.controls.lastName.dirty && helpForm.controls.lastName.touched)">
                            <span *ngIf="helpForm.controls.lastName.errors.required">
                                    Lastname is required
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                SAG-AFTRA ID*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input pInputText class="fluid" type="text" formControlName="sagId">
                        </div>
                        <div *ngIf="helpForm.controls.sagId.invalid && (helpForm.controls.sagId.dirty && helpForm.controls.sagId.touched)">
                            <span *ngIf="helpForm.controls.sagId.errors.required">
                                    SAG-AFTRA ID is required
                                </span>
                            <span *ngIf="helpForm.controls.sagId.errors.pattern || helpForm.controls.sagId.errors.minlength || helpForm.controls.sagId.errors.maxlength">
                                    SAG-AFTRA ID  must be of 8 numbers
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Phone Number*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input pInputText class="fluid" type="text" formControlName="phoneNumber">
                        </div>
                        <div *ngIf="helpForm.controls.phoneNumber.invalid && (helpForm.controls.phoneNumber.dirty && helpForm.controls.phoneNumber.touched)">
                            <span *ngIf="helpForm.controls.phoneNumber.errors.required">
                                    Phone number is required
                                </span>
                            <span *ngIf="helpForm.controls.phoneNumber.errors.pattern || helpForm.controls.phoneNumber.errors.minlength">
                                    Phone number must be of 10 numbers
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Email*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input pInputText class="fluid" type="text" formControlName="email">
                        </div>
                        <div *ngIf="helpForm.controls.email.invalid && (helpForm.controls.email.dirty && helpForm.controls.email.touched)">
                            <span *ngIf="helpForm.controls.email.errors.required">
                                    Email is required
                                </span>
                            <span *ngIf="helpForm.controls.email.errors.pattern">
                                    Not a valid Email
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Issue*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <p-dropdown styleClass="fluid" formControlName="issue" [options]="issues" placeholder="Select">
                            </p-dropdown>
                        </div>
                        <div *ngIf="helpForm.controls.issue.invalid && (helpForm.controls.issue.dirty && helpForm.controls.issue.touched)">
                            <span *ngIf="helpForm.controls.issue.errors.required">
                                    An Issue needs to be selected
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-12 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Description (Please describe your issue in detail)*:
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <textarea pInputTextarea [rows]="5" class="fluid" formControlName="description"></textarea>
                        </div>
                        <div *ngIf="helpForm.controls.description.invalid && (helpForm.controls.description.dirty && helpForm.controls.description.touched)">
                            <span *ngIf="helpForm.controls.description.errors.required">
                                    Description is required
                                </span>
                        </div>
                    </div>
                    <div class="p-sm-12 p-col-12 p-lg-12 p-nogutter">
                        <label class="p-col-12 p-lg-6 row_label">
                                Upload Screenshot
                            </label>
                        <div class="p-col-12 p-lg-6 fluid">
                            <input class="fluid" type="file" (change)="fileUpload($event)" formControlName="imageUpload" accept="image/*">
                        </div>
                    </div>
                    <div class=" margin-auto mb-1 ">
                        <button class="btn-custom fluid help-form-submit " label="Submit" [disabled]="helpForm.invalid" pButton type="submit">
                            </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!--need help form dialog-->
<p-dialog position="bottomright" [(visible)]="formHelpModalHide" [modal]="true" styleClass="login-fail" [closable]="false" [style]="{width: '50vw'}" (onHide)="helpFormValidationReset()" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <p-header class="login-fail-header">Help Form</p-header>
    <form [formGroup]="helpForm" class="p-grid p-justify-start left-align help-form" (ngSubmit)="helpFormSubmit(helpForm)">
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    First Name*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input pInputText class="fluid" type="text" formControlName="firstName">
            </div>
            <div *ngIf="helpForm.controls.firstName.invalid && (helpForm.controls.firstName.dirty && helpForm.controls.firstName.touched)">
                <span *ngIf="helpForm.controls.firstName.errors.required">
                        Firstname is required
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Last Name*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input pInputText class="fluid" type="text" formControlName="lastName">
            </div>
            <div *ngIf="helpForm.controls.lastName.invalid && (helpForm.controls.lastName.dirty && helpForm.controls.lastName.touched)">
                <span *ngIf="helpForm.controls.lastName.errors.required">
                        Lastname is required
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    SAG-AFTRA ID*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input pInputText class="fluid" type="text" formControlName="sagId">
            </div>
            <div *ngIf="helpForm.controls.sagId.invalid && (helpForm.controls.sagId.dirty && helpForm.controls.sagId.touched)">
                <span *ngIf="helpForm.controls.sagId.errors.required">
                        SAG-AFTRA ID is required
                    </span>
                <span *ngIf="helpForm.controls.sagId.errors.pattern || helpForm.controls.sagId.errors.minlength || helpForm.controls.sagId.errors.maxlength">
                        SAG-AFTRA ID  must be of 8 numbers
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Phone Number*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input pInputText class="fluid" type="text" formControlName="phoneNumber">
            </div>
            <div *ngIf="helpForm.controls.phoneNumber.invalid && (helpForm.controls.phoneNumber.dirty && helpForm.controls.phoneNumber.touched)">
                <span *ngIf="helpForm.controls.phoneNumber.errors.required">
                        Phone number is required
                    </span>
                <span *ngIf="helpForm.controls.phoneNumber.errors.pattern || helpForm.controls.phoneNumber.errors.minlength">
                        Phone number must be of 10 numbers
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Email*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input pInputText class="fluid" type="text" formControlName="email">
            </div>
            <div *ngIf="helpForm.controls.email.invalid && (helpForm.controls.email.dirty && helpForm.controls.email.touched)">
                <span *ngIf="helpForm.controls.email.errors.required">
                        Email is required
                    </span>
                <span *ngIf="helpForm.controls.email.errors.pattern">
                        Not a valid Email
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-6 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Issue*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <p-dropdown styleClass="fluid" formControlName="issue" [options]="issues" placeholder="Select">
                </p-dropdown>
            </div>
            <div *ngIf="helpForm.controls.issue.invalid && (helpForm.controls.issue.dirty && helpForm.controls.issue.touched)">
                <span *ngIf="helpForm.controls.issue.errors.required">
                        An Issue needs to be selected
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-12 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Description (Please describe your issue in detail)*:
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <textarea pInputTextarea [rows]="5" class="fluid" formControlName="description"></textarea>
            </div>
            <div *ngIf="helpForm.controls.description.invalid && (helpForm.controls.description.dirty && helpForm.controls.description.touched)">
                <span *ngIf="helpForm.controls.description.errors.required">
                        Description is required
                    </span>
            </div>
        </div>
        <div class="p-sm-12 p-col-12 p-lg-12 p-nogutter">
            <label class="p-col-12 p-lg-6 row_label">
                    Upload Screenshot
                </label>
            <div class="p-col-12 p-lg-6 fluid">
                <input class="fluid" type="file" (change)="fileUpload($event)" formControlName="imageUpload" accept="image/*">
            </div>
        </div>
        <div class=" margin-auto mb-1 ">
            <button class="btn-custom fluid help-form-submit " label="Submit" [disabled]="helpForm.invalid" pButton type="submit">
                </button>
        </div>
    </form>
    <p-footer>
        <button type="button " class="btn-custom-cancel " pButton (click)="helpFormEmitter()" label="Close "></button>
    </p-footer>
</p-dialog>

<!--confirmation success-->
<p-dialog header="Confirmation" [(visible)]="helpFormConfirmation" [modal]="true" styleClass="login-fail" [style]="{width: '50vw'}" (onHide)="helpFormValidationReset()" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <div class="p-grid p-justify-center mt-2 mb-1">
        Details successfully sent.
    </div>
    <p-footer>
        <button type="button " class="btn-custom-cancel " pButton (click)="helpFormConfirmation=false" label="Close "></button>
    </p-footer>
</p-dialog>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { AuthMember } from './login';
import { DUMMY_GET_CYCLE, DUMMY_AUTH_MEMBER, GET_CYCLE_DEV, AUTH_MEMBER_DEV,
        GET_CYCLE_PROD, AUTH_MEMBER_PROD, JSON_SERVER_URL, AUTH_MEMBER_SSO_DEV, AUTH_MEMBER_SSO_PROD,
        AUTH_STAFF_DEV, AUTH_STAFF_PROD, EMAIL_HELP_DEV, EMAIL_HELP_PROD } from 'src/app/utils/constants/service-url-constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _httpClient: HttpClient) { }

  getCycle() {
    return this._httpClient.get(GET_CYCLE_PROD);
  }

  authenticateMember(authMember: AuthMember) {
    return this._httpClient.post(AUTH_MEMBER_PROD, authMember, httpOptions); //, httpOptions
  }

  authenticateMemberSSO(ssoHash) {
    return this._httpClient.post(AUTH_MEMBER_SSO_PROD, ssoHash, httpOptions);
  }

  authenticateStaff(authMember: any) {
    return this._httpClient.post(AUTH_STAFF_PROD, authMember, httpOptions);
  }

  helpFormEmail(formValues: any, image: File, userAgent: string, routePath: string) {
    const formData: FormData = new FormData();
    image && formData.append('imageUpload', image, image.name);
    formData.append('firstName', formValues.firstName);
    formData.append('lastName', formValues.lastName);
    formData.append('sagId', formValues.sagId);
    formData.append('phoneNumber', formValues.phoneNumber);
    formData.append('email', formValues.email);
    formData.append('issue', formValues.issue);
    formData.append('description', formValues.description);
    formData.append('userAgent', userAgent);
    formData.append('routePath', routePath);
    return this._httpClient.post(EMAIL_HELP_PROD, formData);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

    constructor() {}

    public setIsLoaded(value: boolean) {
        this.isLoaded.next(value);
    }

    public getIsLoaded() {
        return this.isLoaded.asObservable();
    }
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { LoginService } from '../services/login/login.service';

import { SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../utils/loader.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  helpForm: FormGroup;
  imageToUpload: File;
  issues: SelectItem[];
  staticHelpPage: boolean = false;
  helpFormConfirmation: boolean = false;
  routeData: any;

  @Input() formHelpModalHide : boolean;
  @Output() formHelpModalShow: EventEmitter<boolean> = new EventEmitter();

  constructor(private loginService: LoginService,
              private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private loaderService: LoaderService) {
    // this.messageService.messageObserver.subscribe(resp => {
    //   console.log(resp);
    // });
    this.loaderService.setIsLoaded(false);
    this._activatedRoute.data.subscribe(data => {
      this.staticHelpPage = data.staticHelpPage;
    });
    this.routeData = this._router.url;
    console.log(this.routeData);
    this.helpForm = new FormGroup({
      'firstName': new FormControl('', Validators.required),
      'lastName': new FormControl('', Validators.required),
      'sagId': new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]),
      'phoneNumber': new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
      'email': new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.]+(@){1}[a-zA-z]+(\.{1}[a-zA-z]{2,})+$')])),
      'description': new FormControl('', Validators.required),
      'issue': new FormControl('', Validators.required),
      'imageUpload': new FormControl(''),
    });

    this.issues = [
      {label: 'Logging In', value: 'Logging In'},
      {label: 'Candidates Requesting Nomination Petitions', value: 'Candidates Requesting Nomination Petitions'},
      {label: 'Signing a Candidate\'s Petition', value: 'Signing a Candidate\'s Petition'},
      {label: 'Uploading/Saving Candidate Photo/Statement', value: 'Uploading/Saving Candidate Photo/Statement'},
      {label: 'Question Regarding Eligibility', value: 'Question Regarding Eligibility'},
      {label: 'Other - Please Provide Details in Description', value: 'Other - Please Provide Details in Description'},
    ];
  }

  ngOnInit(): void {
  }

  fileUpload(event: any) {
    this.imageToUpload = event.target.files[0];
  }

  helpFormSubmit(helpForm: FormGroup) {
    console.log(helpForm.value);
    let userAgent = window.navigator.userAgent;
    this.loginService.helpFormEmail(helpForm.value, this.imageToUpload, userAgent, this.routeData).subscribe(resp => {
      console.log(resp);
      if ((resp['http_code']===200) && (resp['status']===true)) {
        this.formHelpModalHide = false;
        if (this.staticHelpPage) {
          this.helpFormConfirmation = true;
        }
      }
    });
  }

  helpFormValidationReset() {
    this.helpForm.reset();
  }

  helpFormEmitter() {
    console.log("from emitter", this.formHelpModalHide);
    this.formHelpModalShow.emit(false);
  }

}

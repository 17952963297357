import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { StaffGuard } from './staff.guard';

import { UserIdleModule } from 'angular-user-idle'

// feature modules
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HelpModule } from './help/help.module';

import { LogoModule } from './logo/logo.module';
import { ApplicationTitleService } from './utils/application-title.service';
import { HttpInterceptorsService } from './services/custominterceptors/http-interceptors.service';

// primeNg modules
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'staff-login', component: LoginComponent, data: {staff: true}},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [StaffGuard] },
  { path: 'help', loadChildren: () => import('./help/help.module').then(m=>m.HelpModule)},
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    LogoModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    DialogModule,
    ToastModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    BlockUIModule,
    HelpModule,
    RouterModule.forRoot(appRoutes),
    UserIdleModule.forRoot({ idle: 3600, timeout: 600, ping: 20 }),
  ],
  providers: [
    AuthGuard,
    Title,
    ApplicationTitleService,
    MessageService,
    // { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorsService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="p-grid sample-layout">
    <div class="p-col-12 p-col-nogutter">
        <!-- <div class="p-grid p-align-end header-bar"> -->
        <app-logo></app-logo>
        <!-- </div> -->
    </div>
    <div class="p-col-8 p-lg-12 p-md-12 p-sm-8 p-col-nogutter outlet">
        <div class="login-body">
            <div class="p-grid">
                <div class="p-col-12 h-3">
                    <h3>Welcome to the Nominations Portal for the 2023 SAG-AFTRA Elections.</h3>
                </div>
            </div>
            <p-card>
                <div class="p-col-12">
                    <div class="h-1">
                        Page not found
                    </div>
                    <div class="mt-2">
                        The Resource you are looking for does not exists
                    </div>
                    <div class="mt-1">
                        Return to <a routerLink="/login">Login page</a>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
    <div class="p-col-12 footer-bar">
        <div class="p-grid p-col-nogutter p-align-end">
            <div class="p-col-9 p-lg-11 p-sm-10" style="height: 1.5em;background-color: #FED100;"></div>
            <a class="p-col-3 p-lg-1 p-sm-2" style="height: 3em;background-color: red;padding:1em;text-align:center;color:white;text-decoration:none" routerLink="/home/help">
                HELP
            </a>
        </div>
    </div>
</div>

export interface iAuthMember {
    username: string;
    password: string;
}

export class AuthMember implements iAuthMember{

    username: string = '';
    password: string = '';


}
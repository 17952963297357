import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffGuard implements CanActivate {

  staffKey: any;

  constructor(private _router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.staffKey = document.cookie.split(';').filter((item) => item.trim().startsWith('staff_key='))[0];
      if (this.staffKey) {
        if (this.staffKey.split("=")[1]) {
          return true;
        }
      } else {
        document.cookie = "staff_key=; max-age=-100000; Path=/";
        this._router.navigate(['staff-login']);
      }
  }
  
}

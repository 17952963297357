import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LoaderService } from './utils/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  signPetitions: any;
  signPetitionsCode: any;
  authAccessKey = document.cookie.split(";").filter((item) => item.trim().startsWith('access_key='))[0];
  showLoader: boolean = true;

  constructor(private _router: Router,
              private _location: Location,
              private loaderService: LoaderService) {
    if (location.href.includes('signing_code')) {
      this.signPetitions = location.href.split('=').length - 1;
      this.signPetitionsCode = location.href.split('=')[this.signPetitions];
      document.cookie = `sign_route=${this.signPetitionsCode}; SameSite=lax; Path=/; Secure=true`;
      this._location.go('login');
      if (this.authAccessKey) {
        this._router.navigate(['home/sign-petitions']);
      }
    }

    this.loaderService.getIsLoaded().subscribe(data => {
      if (typeof data === "boolean") {
        this.showLoader = data;
      }
    })

  }

  ngOnInit() { }

}

import { Component, OnInit } from '@angular/core';
import { ApplicationTitleService } from '../utils/application-title.service';
import { LoaderService } from '../utils/loader.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  year = 2023;

  constructor(private applicationTitleService: ApplicationTitleService, private loaderService: LoaderService) {
    this.loaderService.setIsLoaded(false);
    this.applicationTitleService.setTitle('Page Not Found | Nominations');
  }

  ngOnInit(): void { }

}
